const money = (value, withCurrency = true) => {
    if (value < 0 || value == null) {
        return '?';
    } else {
        const formatter = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 });
        return `${formatter.format(value)}${withCurrency ? ' €' : ''}`;
    }
}

const osmUrl = (amenity) => {
    return `https://www.openstreetmap.org/?mlat=${amenity.lat}&mlon=${amenity.lon}#map=19/${amenity.lat}/${amenity.lon}`
}

const googleMapsUrl = (amenity) => {
    return `https://www.google.com/maps/search/?api=1&query=${amenity.lat}%2C${amenity.lon}`;
}

export default { money, googleMapsUrlFormatter: googleMapsUrl, osmUrlFormatter: osmUrl }