import formatter from "./formatter";

export default (element, amenity) => {
    const clonedElement = element.cloneNode(false);
    clonedElement.innerHTML = `
        <h3 class="small">${amenity.name}</h3>
        <div class="small-space"></div>
        <div>${amenity.type}</div>
        <div class="divider" style="margin: 5px 0 25px 0"></div>
        <div class="grid">
            <div class="s4">Espresso</div>
            <div class="s8">${formatter.money(amenity.espressoPrice)}</div>
            ${showComment(amenity.espressoComment)}
            <div class="s4">Mineral</div>
            <div class="s8">${formatter.money(amenity.mineralPrice)}</div>
            ${showComment(amenity.mineralComment)}
            <div class="s4">Google Maps</div>
            <div class="s8"><a href="${formatter.googleMapsUrlFormatter(amenity)}" target="_blank"><img src="lib/location-on.svg" width="20" height="20"/></a></div>
            <div class="s4">OSM</div>
            <div class="s8"><a href="${formatter.osmUrlFormatter(amenity)}" target="_blank"><img src="lib/location-on.svg" width="20" height="20"/></a></div>
            <div class="s4">Web</div>
            <div class="s8"><a href="${amenity.website}" target="_blank">${amenity.website}</a></div>
            <div class="s4">Kommentar</div>
            <div class="s8">${amenity.comment}</div>
        </div>
        <nav class="right-align">
            <button data-ui="#dialog">Close</button>
        </nav>`;
    return clonedElement;
}

const showComment = (comment) => {
    if (comment) {
        return `
            <div class="s4">Zusatzinfo</div>
            <div class="s8">${comment}</div>`;
    }
    return '';
}