const tableFilter = (filterElement, table) => {
    filterElement.addEventListener("change", e => {
        let type = e.target.value;
        console.log(type);
        table.alert("Filtering...");
        setTimeout(() => {
            if (type === 'ALL') {
                table.clearFilter();
            } else {
                table.setFilter("type", "=", type);
            }
            table.clearAlert();
        }, 1);
    });
};
export default tableFilter;