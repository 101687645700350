//import beercss from "beercss";
import tableFilter from "./components/table-filter";
import formatter from "./components/formatter";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import amenityDetail from "./components/amenity";

//when bundled with plugin like styles() the css is copied to bundled css
//import  "tabulator-tables/dist/css/tabulator_bootstrap5.min.css";


async function editPrice(cell) {
    let id = cell.getData().id;
    const field = cell.getField();
    const value = cell.getValue();
    const response = await fetch(`/amenities/${id}?priceType=${field}&price=${value}`, {
        method: 'PATCH',
    });
}

function registerCellEventHandler(cell) {
    console.log('registering eventhandler');
    cell.getElement().addEventListener('click', e => {
        console.log('clicked...');
        const targetElement = document.getElementById('amenity-detail');
        const newElement = amenityDetail(targetElement, cell.getData());
        targetElement.replaceWith(newElement);
        //document.getElementById('dialog').classList.add('active');
        //document.getElementById('overlay').classList.add('active');
    });
    cell.getElement().dataset.ui = '#dialog';
}

function priceFormatter(cell, formatterParams, onRendered) {
    if (!(document.cookie.includes('loggedIn'))) {
        registerCellEventHandler(cell);
    }
    let price = cell.getValue();
    let marker = '';
    if ((cell.getField() === 'mineralPrice' && cell.getData().mineralComment) ||
        (cell.getField() === 'espressoPrice' && cell.getData().espressoComment)) {
        marker = '*';
    }
    return `${marker} ${(formatter.money(price, false))} <span class="grey-text">€</span>`;
}

const initTable = () => {
    return new Tabulator("#tabulator-table", {
        //ajaxURL: "/amenities/",
        //dataLoaderLoading: '<div style=\'display:inline-block; border:1px solid var(--brown-text); border-radius:5px; background:#ccc; font-weight:bold; font-size:14px; color:#000; padding:10px 20px;\'>Lade Daten...</div>',
        pagination: "local",
        paginationSize: 30,
        resizableColumnFit: true,
        initialSort: [{ column: "espressoPrice", dir: "desc" },],
        columns: [
            {
                field: "name",
                title: "Name",
                width: "40%",
                vertAlign: "middle",
                resizable: false,
                formatter: function (cell, formatterParams, onRendered) {
                    registerCellEventHandler(cell);
                    return '<i class="grey-text">more_vert</i>' + cell.getValue();
                }
            },
            {
                field: "espressoPrice",
                title: "<i>local_cafe</i>",//"<img src=\"/lib/coffee-bean.svg\" alt=\"Coffee bean\" style=\"width: 18px; height: 20px;\"/> €",
                titleFormatter: "html",
                resizable: false,
                hozAlign: "right",
                headerHozAlign: "right",
                sorter: "number",
                sorterParams: {
                    alignEmptyValues: "bottom",
                },
                headerSortStartingDir: "desc",
                editor: document.cookie.includes('loggedIn') ? "number" : false,
                width: "15%",
                minWidth: 70,
                formatter: priceFormatter,
                cellEdited: editPrice,
            },
            {
                field: "mineralPrice",
                title: "<i>water_drop</i>",
                titleFormatter: "html",
                resizable: false,
                hozAlign: "right",
                headerHozAlign: "right",
                sorter: "number",
                sorterParams: {
                    alignEmptyValues: "bottom",
                },
                headerSortStartingDir: "desc",
                editor: document.cookie.includes('loggedIn') ? "number" : false,
                width: "15%",
                minWidth: 70,
                formatter: priceFormatter,
                cellEdited: editPrice,
            },
            {
                field: "location",
                title: "<i>map</i>",
                headerSort: false,
                resizable: false,
                hozAlign: "center",
                headerHozAlign: "center",
                width: "10%",
                titleFormatter: "html",
                formatter: function (cell, formatterParams, onRendered) {
                    return `<a href="${formatter.googleMapsUrlFormatter(cell.getData())}" target="_blank"><img src="lib/location-on.svg" width="24" height="24"/></a>`;
                }
            },
            {
                field: "website",
                title: "<i>home</i>",
                headerSort: false,
                resizable: true,
                titleFormatter: "html",
                formatter: function (cell, formatterParams, onRendered) {
                    if (cell.getValue()) {
                        return `<a href="${cell.getValue()}" target="_blank"><i>link</i></a>`;
                    }
                }
            }
        ],
        layout: "fitDataStretch",
    });
}

const transformAmenities = (amenities) => {
    const mapped = amenities.map(a => {
        return {
            id: a.id,
            name: a.name,
            comment: a.comment,
            type: a.type,
            espressoPrice: latestPriceOrNull(a.espressoPrices),
            espressoComment: (a.espressoPrices && a.espressoPrices.length) ? a.espressoPrices[a.espressoPrices.length - 1].comment : '',
            mineralPrice: latestPriceOrNull(a.mineralPrices),
            mineralComment: (a.mineralPrices && a.mineralPrices.length) ? a.mineralPrices[a.mineralPrices.length - 1].comment : '',
            website: a.website,
            lat: a.lat,
            lon: a.lon,
        };
    });
    return mapped;
}

const latestPriceOrNull = (prices) => {
    let espressoPrice = (prices && prices.length) ? prices[prices.length - 1].price : -1;
    if (espressoPrice === -1) {
        espressoPrice = null;
    }
    return espressoPrice;
}

async function loadAmenities() {
    const table = initTable();
    table.on("tableBuilt", async () => {
        table.alert("Lade Daten...");
        const response = await fetch("/amenities/");
        if (response.ok) {
            const amenities = await response.json();
            const mapped = transformAmenities(amenities);
            table.setData(mapped);
            table.clearAlert();
        } else {
            // TODO better error message
            alert('response was not ok!');
        }
        //table.setFilter("type", "=", "CAFE");
        tableFilter(document.getElementById("type-selection"), table);
    });
}

const renderMail = (lhs, rhs) => {
    const aElement = document.getElementById('say-hello');
    let contactTarget = `${lhs}@${rhs}.org`;
    aElement.setAttribute('href', 'mailto:' + contactTarget);
    aElement.innerText = contactTarget;
}

const registerUmamiEvents = () => {
    const typeSelection = document.getElementById('type-selection');
    typeSelection.addEventListener('change', function (e) {
        const value = e.target.value;
        umami.track(value);
    })
}
renderMail('barista', 'espressoindex');
registerUmamiEvents();
loadAmenities();
